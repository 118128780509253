<template>
	<h1>Login</h1>
	<h2>Welcome to your personal project space</h2>
</template>

<script>
export default {
	data() {
		return {};
	},
	name: "Login",
	props: {
		msg: String,
	},
};
</script>
<style></style>
